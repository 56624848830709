import React from "react"
import { Services } from "../pages/Services"
import { Hero } from "./Hero"
export const Home = () => {
  return (
    <>
      <Hero />
      <Services />
    </>
  )
}
